/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
window.vg.exports(
  // Nombre del módulo
  'summary',

  // Factoria del módulo
  function moduleFactory() {
    const idioma = vg.utils.lang();
    const polyglot = new window.Polyglot();
    // Traducciones para diferentes idiomas
    polyglot.extend({
      summary: {
        es: {tituloIndice: 'Índice de la página:', buttonHiddenText: 'Ocultar'},
        eu: {tituloIndice: 'Web-orriaren aurkibidea:', buttonHiddenText: 'Ezkutatu'},
        en: {tituloIndice: 'Page index:', buttonHiddenText: 'Hide'},
        fr: {tituloIndice: 'Index des pages :', buttonHiddenText: 'Cacher'},
        invalid: 'El contenido no es válido para crear el resumen.',
      },
    });


    let contenedor; let element; let headings; let currentElement; let listado; let details;
    const minH2Elements = 3;
    const maxLength = 1500;
    const maxOffsetHeight = 200;

    /**
     * Comprueba si el dispositivo es móvil.
     * @returns {boolean} True si es móvil, false en caso contrario.
     */
    const isMobileDevice = () => vg.utils.isMobileDevice() || window.innerWidth < 768;

    /**
     * Controla la visibilidad del elemento <details> según el tipo de dispositivo.
     */
    const toggleDetails = () => {
      if (!isMobileDevice()) {
        details.setAttribute('open', ''); // Siempre abierto en escritorio
      } else {
        details.removeAttribute('open'); // Funciona normal en móvil
      }
      eventLinkSticky();
    };

    /* const u = (t, e) => {
      if (t) {
        if ('string' == typeof t) {
          return s(t, e);
        }
        var n = {}.toString.call(t).slice(8, -1);
        return 'Object' === n && t.constructor && (n = t.constructor.name),
        'Map' === n || 'Set' === n ? Array.from(t) : 'Arguments' === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? s(t, e) : void 0;
      }
    };
    const s = (t, e) => {
      (null == e || e > t.length) && (e = t.length);
      for (var n = 0, r = Array(e); n < e; n++) {
        r[n] = t[n];
      }
      return r;
    };
*/
    /**
     * Actualiza el encabezado actual destacado en el índice.
     */
    /* const updateCurrentHeader = () => {
      var t; var e = isMobileDevice() ? element.offsetTop + element.offsetHeight : 0; var n = (t = headings,
      // var t; var e = isMobileDevice() ? element.offsetHeight: 0; var n = (t = headings,
      function (t) {
        if (Array.isArray(t)) {
          return s(t);
        }
      }(t) || function (t) {
        if ('undefined' != typeof Symbol && null != t[Symbol.iterator] || null != t['@@iterator']) {
          return Array.from(t);
        }
      }(t) || u(t) || function () {
        throw new TypeError('Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
      }()).findLast((function (t) {
        console.log(`t: ${t.id}, t.offsetTop: ${t.offsetTop}, window.scrollY: ${window.scrollY}, e: ${e} `);
        // return t.offsetTop <= window.scrollY + e + 1;
        return t.offsetTop <= e + 20;
      }));

      n ? (currentElement.classList.add('visible'),
      currentElement.textContent = ''.concat(n.textContent || ''),
      currentElement.href = '#'.concat(n.id || '')) : headings.length > 0 ? (currentElement.classList.add('visible'),
      currentElement.textContent = ''.concat(headings[0].textContent || ''),
      currentElement.href = '#'.concat(headings[0].id || '')) : currentElement.classList.remove('visible');
    };*/


    const updateCurrentHeader = () => {
      // const offset = isMobileDevice() ? element.offsetTop + element.offsetHeight : 0;
      const offset = isMobileDevice() ? element.offsetTop + maxOffsetHeight: 0;
      const activeHeading = [...headings].reverse().find((h) => h.offsetTop <= offset + 20);

      if (activeHeading) {
        currentElement.classList.add('visible');
        currentElement.textContent = activeHeading.textContent;
        currentElement.href = `#${activeHeading.id}`;
      } else if (headings.length > 0) {
        currentElement.classList.add('visible');
        currentElement.textContent = headings[0].textContent;
        currentElement.href = `#${headings[0].id}`;
      } else {
        currentElement.classList.remove('visible');
      }
    };

    /**
     * Agrega eventos a los enlaces del índice para realizar scroll suave.
     */
    const eventLinkSticky = () => {
      element.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', function (event) {
          event.preventDefault(); // Evita el salto automático del navegador
          // event.stopImmediatePropagation(); // Evita que otros eventos del mismo tipo en el mismo elemento se ejecuten

          const targetId = this.getAttribute('href').substring(1);
          const targetElement = document.getElementById(targetId);

          if (targetElement) {
            // Modificamos la URL sin recargar la página
            history.pushState(null, '', `#${targetId}`);

            currentElement.classList.add('visible');
            currentElement.textContent = targetElement.textContent;
            currentElement.href = `#${targetId}`;

            let offsetTop = 0;
            if (isMobileDevice()) {
              details.removeAttribute('open');
              offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - element.offsetHeight - 10;
            } else {
              offsetTop = targetElement.getBoundingClientRect().top + window.scrollY - 10;
            }

            window.scrollTo({top: offsetTop, behavior: 'smooth'});
          }
        });
      });
    };

    /**
     * Valida si el contenido cumple con los criterios para generar el índice.
     */
    function validateContent() {
      // Seleccionar todos los h2 y h3 que estén dentro del contenedor después del nav
      let nivelesBusqueda = 'h2:not(.news-list h2, .main__grid-box h2)';
      if (element.dataset.summaryHeaders) {
        nivelesBusqueda += ', h3';
      }

      headings = [...contenedor.querySelectorAll(nivelesBusqueda)]
        .filter((el) => element.compareDocumentPosition(el) & Node.DOCUMENT_POSITION_FOLLOWING);


      if (headings.length >= minH2Elements && contenedor.innerText.length >= maxLength) {
        // Asignar ID a los encabezados si no tienen
        headings.forEach((header, index) => {
          if (!header.id) header.id = `summary_title_${index + 1}`;
        });
        createSummary(headings);

        window.addEventListener('scroll', updateCurrentHeader);
        updateCurrentHeader(); // Llamamos para establecer el primer título
      } else {
        console.log(polyglot.t('summary.invalid'));
      }
    }

    /**
     * Crea la lista de títulos dentro del índice.
     */
    function createListTitles() {
      // Crear la lista de enlaces a los títulos
      listado = document.createElement('ul');
      listado.setAttribute('class', 'summary-list');

      let lastH2Li = null; let sublist = null;

      headings.forEach((header) => {
        const li = document.createElement('li');
        const link = document.createElement('a');
        link.href = `#${header.id}`;
        link.textContent = header.textContent;
        li.appendChild(link);

        if (header.tagName === 'H2') {
          // Insertamos el h2 directamente en la lista principal
          li.classList.add('summary-element');
          listado.appendChild(li);
          lastH2Li = li; // Guardamos referencia al h2 actual
          sublist = null; // Reiniciamos la sublista
        } else if (header.tagName === 'H3' && lastH2Li) {
          // Si el último H2 no tiene un UL todavía, lo creamos
          if (!sublist) {
            sublist = document.createElement('ul');
            sublist.classList.add('sub-summary-list');
            lastH2Li.appendChild(sublist);
          }
          li.classList.add('sub-summary-element');
          sublist.appendChild(li);
        }
      });

      details.appendChild(listado);
    }
    /**
     * Función para crear el elemento summary.
     * @param {*} headdings
     */
    function createSummary(headdings) {
      // Crear el <details>
      details = document.createElement('details');
      details.classList.add('details--summary');

      const summary = document.createElement('summary');
      summary.textContent = polyglot.t(`summary.${idioma}.tituloIndice`);

      // Crear el enlace del título actual
      currentElement = document.createElement('a');
      currentElement.id = 'summary-current';
      currentElement.href = `#${headings[0].id}`;
      currentElement.textContent = headings[0].textContent;
      currentElement.classList.add('summary__current-element');

      details.appendChild(summary);
      createListTitles(details);

      element.appendChild(details);
      element.appendChild(currentElement);
      element.classList.add('summary');

      toggleDetails();

      details.addEventListener('toggle', function () {
        if (this.open && isMobileDevice() && element.getBoundingClientRect().top > 0) {
          this.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
      });

      window.addEventListener('resize', function () {
        toggleDetails();
      });
    }
    return {
      init: function () {
        contenedor = document.querySelector('#wb021contenido');
        if (contenedor) {
          element = contenedor.querySelector('div[data-summary=true]');
          if (element) validateContent();
        }
      },
    };
  }
);
