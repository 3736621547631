window.vg.exports(
  // Nombre del módulo
  'carouselFechas',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;
    var mesLargo = {
      es: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
      eu: [
        'urtarrila',
        'otsaila',
        'martxoa',
        'apirila',
        'maiatza',
        'ekaina',
        'uztaila',
        'abuztua',
        'iraila',
        'urria',
        'azaroa',
        'abendua',
      ],
      en: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      fr: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
    };
    var mesCorto = {
      es: [
        'en.',
        'feb.',
        'mar.',
        'abr.',
        'may.',
        'jun.',
        'jul.',
        'agto.',
        'sept.',
        'oct.',
        'nov.',
        'dic.',
      ],
      eu: [
        'urt.',
        'ots.',
        'mar.',
        'api.',
        'mai.',
        'eka.',
        'uzt.',
        'abu.',
        'ira.',
        'urr.',
        'aza.',
        'abe.',
      ],
      en: [
        'Jan.',
        'Feb.',
        'Mar.',
        'Apr.',
        'May.',
        'Jun.',
        'Jul.',
        'Aug.',
        'Sep.',
        'Oct.',
        'Nov.',
        'Dec.',
      ],
      fr: [
        'janv.',
        'févr.',
        'mars.',
        'avril',
        'mai',
        'juin',
        'juil',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.',
      ],
    };
    var diasLargos = {
      es: [
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado',
        'domingo',
      ],
      eu: [
        'astelehena',
        'asteartea',
        'asteazkena',
        'osteguna',
        'ostirala',
        'larunbata',
        'igandea',
      ],
      en: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      fr: [
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
        'dimanche',
      ],
    };
    var diasCortos = {
      es: ['lun.', 'mart.', 'miérc.', 'juev.', 'vier.', 'sáb.', 'dom.'],
      eu: ['al.', 'as.', 'az.', 'og.', 'ol.', 'lr.', 'ig.'],
      en: ['Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat.', 'Sun.'],
      fr: ['lun.', 'mard.', 'mer.', 'jeu.', 'vend.', 'sam,', 'dim.'],
    };

    var observerPattern = {
      /**
       * Mixin para aplicar el rol de sujeto (observable) del patron Observer a
       * un objeto.
       *
       * @param {Object} obj  Objeto al que aplicar el patron
       * @return {Object}     Objeto con el rol aplicado
       */
      observableMixin: function observableMixin(obj) {
        return Object.assign(obj, {
          observers: [],
          registerObserver: function (observer) {
            this.observers.push(observer);
          },
          notifyObservers: function (observable) {
            this.observers.forEach(function (observer) {
              observer.update(observable);
            });
          },
        });
      },

      /**
       * Mixin para aplicar el rol de observador del patron Observer a un
       * objeto.
       *
       * @param {Object} obj Objeto al que aplicar el patron
       * @param {Function} fnUpdate función update al ser notificado
       * @return {Object} Objeto con el rol aplicado
       */
      observerMixin: function observerMixin(obj, fnUpdate) {
        return Object.assign(obj, {
          update: fnUpdate,
        });
      },
    };

    /**
     * Devuelve un objeto que representa a una pestaña
     * @param  {Object} item Item
     * @param  {Array} functions functions funciones
     * @param  {String} itemSelected functions funciones
     * @param  {Element} nodeListado Capa padre
     * @param  {boolean} addBefore Añadir el primero
     * @return {Object} Objeto Carrusel fechas
     */
    function itemFactory(item, functions, itemSelected, nodeListado,
      addBefore) {
      var node;
      var buttonNode;
      var returnedObject;
      var selected = false;
      var uid = utils.uid();

      /**
       * Crea el elemento
       */
      function createElement() {
        node = document.createElement('div');

        node.setAttribute('role', 'listItem');
        node.classList.add('carousel__item');

        createButtonItem();

        if (addBefore) {
          nodeListado.prepend(node);
        } else {
          nodeListado.appendChild(node);
        }

        returnedObject.element = node;
        returnedObject.elementButton = buttonNode;
      }

      /**
       * Devuelve el mes en formato largo
       * y en el idioma correspondiente.
       * Ejemplo: Enero
       * @param  {Date} fecha Fecha
       * @return {String} diaSemana Dia de la semana
       * en formato largo
       */
      function getMesLargo(fecha) {
        return mesLargo[document.documentElement.lang][fecha.getMonth()];
      }

      /**
       * Devuelve el mes en formato corto
       * y en el idioma correspondiente.
       * Ejemplo: Ene
       * @param  {Date} fecha Fecha
       * @return {String} diaSemana Dia de la semana
       * en formato largo
       */
      function getMesCorto(fecha) {
        return mesCorto[document.documentElement.lang][fecha.getMonth()];
      }

      /**
       * Devuelve el dia de la semana en formato largo
       * y en el idioma correspondiente.
       * Ejemplo: Lunes
       * @param  {Date} fecha Fecha
       * @return {String} diaSemana Dia de la semana
       * en formato largo
       */
      function getDiaLargo(fecha) {
        return diasLargos[document.documentElement.lang][fecha.getUTCDay()];
      }

      /**
       * Devuelve el dia de la semana en formato corto
       * y en el idioma correspondiente.
       * Ejemplo: Lun
       * @param  {Date} fecha Fecha
       * @return {String} diaSemana Dia de la semana
       * en formato largo
       */
      function getDiaCorto(fecha) {
        return diasCortos[document.documentElement.lang][fecha.getUTCDay()];
      }

      /**
       * Asigna las funciones que se pasan por parametro al control de dias.
       * Por defecto, siempre añade el evento click.
       */
      function addFunctionsButton() {
        if (functions) {
          var functionSize = functions.length;
          var existeFuncionClick = false;
          for (var i = 0; i < functionSize; i++) {
            var funcion = functions[i];

            if (funcion.type == 'click') {
              existeFuncionClick = true;
              buttonNode.addEventListener(funcion.type, function (evt) {
                evt.preventDefault();
                returnedObject.showLoad();
                returnedObject.select();
                // carruselDias.moveSelected();
                funcion.callback(evt).then(returnedObject.hideLoad);
              });
            } else {
              buttonNode.addEventListener(funcion.type, function (evt) {
                evt.preventDefault();
                funcion.callback(evt);
              });
            }
          }

          if (!existeFuncionClick) {
            buttonNode.addEventListener('click', function (evt) {
              evt.preventDefault();
              returnedObject.select();
              // carruselDias.moveSelected();
            });
          }
        }
      }

      /**
       * Crea el boton dia
       */
      function createButtonItem() {
        var fechaString = item.fecha.toString();
        var itemSeleccionado = itemSelected.toString();
        var dia = fechaString.substr(6, 2);
        var mes = fechaString.substr(4, 2);
        var anyo = fechaString.substr(0, 4);
        var fechaDate = new Date(anyo, Number(mes) - 1, dia);

        buttonNode = document.createElement('button');
        buttonNode.classList.add('item__button');
        buttonNode.classList.add('item__button--days');

        if (itemSeleccionado === fechaString) {
          buttonNode.classList.add('is-selected');
        }

        buttonNode.value = fechaString;
        buttonNode.dataset.diasemanalargo = getDiaLargo(fechaDate);
        buttonNode.dataset.diasemanacorto = getDiaCorto(fechaDate);
        buttonNode.dataset.meslargo = getMesLargo(fechaDate);
        buttonNode.dataset.mescorto = getMesCorto(fechaDate);
        buttonNode.dataset.dia = dia;
        buttonNode.dataset.month = parseInt(mes, 10) - 1;
        buttonNode.dataset.year = parseInt(anyo, 10);
        buttonNode.textContent = dia;

        node.appendChild(buttonNode);

        addFunctionsButton();
      }

      returnedObject = {
        deselect: function () {
          if (selected) {
            buttonNode.setAttribute('aria-selected', false);
            buttonNode.classList.remove('is-selected');
            selected = false;
            this.notifyObservers(this);
          }
        },
        element: node,
        elementButton: buttonNode,
        focus: function () {
          buttonNode.focus();
        },
        getPositionRelative: function () {
          return node.getBoundingClientRect();
        },
        getPositionRelativeButton: function () {
          return buttonNode.getBoundingClientRect();
        },
        hideLoad: function () {
          buttonNode.textContent = buttonNode.dataset.dia;
        },
        isEqual: function (tab) {
          return uid === tab.uid();
        },
        isDay: function (dia) {
          return buttonNode.value === dia;
        },
        isSelected: function () {
          return selected;
        },
        offsetWidth: function () {
          return node.offsetWidth;
        },
        flex: function (width) {
          return (node.style.flex = '0 0 ' + width + 'px');
        },
        select: function () {
          if (!selected) {
            buttonNode.setAttribute('aria-selected', true);
            // node.setAttribute('tabindex', '0');
            buttonNode.classList.add('is-selected');
            selected = true;
            this.notifyObservers(this);
          }
        },
        showLoad: function () {
          buttonNode.innerHTML = '<span class="load"></span>';
        },
        uid: function () {
          return uid;
        },
      };
      observerPattern.observableMixin(returnedObject);

      (function init() {
        createElement();

        selected =
          buttonNode.classList.contains('is-selected') ||
          buttonNode.getAttribute('aria-selected') === 'true';

        if (selected) {
          buttonNode.setAttribute('aria-selected', true);
          buttonNode.classList.add('is-selected');
          returnedObject.select();
        } else {
          buttonNode.setAttribute('aria-selected', false);
          buttonNode.classList.remove('is-selected');
        }
      })();

      return returnedObject;
    }

    /**
     * Aplicar el patrón fachada a una pestaña para exponer fuera del módulo
     * sólo la funcionalidad necesaria.
     *
     * Por ejemplo: Objeto tab que se envía junto con un evento.
     *
     * @param {Object} item  Objeto item
     * @return {Object}     Objeto simplificado
     */
    function exposedTabFacade(item) {
      return {
        element: item.element,
        elementButton: item.elementButton,
        deselect: item.deselect.bind(item),
        hideLoad: item.hideLoad.bind(item),
        offsetWidth: item.offsetWidth.bind(item),
        flex: item.flex.bind(item),
        getPositionRelative: item.getPositionRelative.bind(item),
        getPositionRelativeButton: item.getPositionRelativeButton.bind(item),
        isSelected: item.isSelected.bind(item),
        isDay: item.isDay.bind(item),
        select: item.select.bind(item),
        showLoad: item.showLoad.bind(item),
      };
    }

    /**
     * Instancia y genera la funcionalidad de un carousel de fechas
     * @param  {Element} carousel Elemento DOM padre del carousel
     * @param  {object} options Objeto con las opciones posibles del carousel
     * @return {undefined}
     */
    function carouselFactory(carousel, options) {
      var _options = options;
      var node = carousel;
      var nodeContainer;
      var nodeListado;
      var nodeNav;
      var nodeButtonLeft;
      var nodeButtonRight;
      var items;
      var _coordinates = [];
      var _showItems = options.showItems || 0;
      var _numItemsVisibles;
      var _widthItems;
      var _ocultarNav = options.ocultarNav;
      var _widthMax;
      var _alignCenter = options.center || false;


      /**
       * Devuelve el indice de una tab dentro del array de tabs
       * @param  {Object} item Objeto Item
       * @return {Number}     index
       */
      function getItemIndex(item) {
        for (var i = 0, max = items.length; i < max; i++) {
          if (item.isEqual(items[i])) {
            return i;
          }
        }
        return -1;
      }

      /**
       * Devuelve las pestañas hermanas de una determinada
       * @param  {Object} item Objeto Item
       * @return {Array}      Pestañas hermanas
       */
      function getSiblings(item) {
        var cloned = items.slice(0);
        // Eliminar la tab concreta
        cloned.splice(getItemIndex(item), 1);
        return cloned;
      }

      var returnedObject = {
        addAfter: addItemsAfter,
        addBefore: addItemsBefore,
        element: node,
        getSelected: getValueItemSelected,
        moveSelected: moveItemSelected,
        set: setItems,
        select: moveItemToSelect,
        selectFirstElement: moveToFirstDay,
      };

      observerPattern.observerMixin(returnedObject, function (observableItem) {
        if (observableItem.isSelected()) {
          getSiblings(observableItem).forEach(function (sibling) {
            sibling.deselect();
          });
          var customItemSelectEvt = new CustomEvent('vg.carouselItemSelect', {
            detail: {
              button: exposedTabFacade(observableItem),
              index: getItemIndex(observableItem),
            },
          });
          node.dispatchEvent(customItemSelectEvt);
        }
      });

      /**
       * Crea la estructura del carousel
       */
      function crearEstructura() {
        node.classList.add('carousel__fechas');

        nodeContainer = document.createElement('div');
        nodeContainer.classList.add('carousel__container');
        node.appendChild(nodeContainer);

        nodeListado = document.createElement('div');
        nodeListado.classList.add('carousel__list');
        nodeListado.setAttribute('role', 'list');
        nodeListado.style.left = 0;
        nodeContainer.appendChild(nodeListado);

        nodeNav = document.createElement('div');
        nodeNav.classList.add('carousel__nav');
        node.appendChild(nodeNav);

        nodeButtonLeft = document.createElement('button');
        nodeButtonLeft.classList.add('nav__button');
        nodeButtonLeft.classList.add('nav__button--prev');
        nodeButtonLeft.dataset.dir = 'prev';
        nodeButtonLeft.innerHTML =
          '<svg class="button__svg" ' +
          'enable-background="new 8 6 7.41 12" viewBox="8 6 7.41 12" ' +
          'xmlns="http://www.w3.org/2000/svg"><path d="M15.41,7.41L14,' +
          '6l-6,6l6,6l1.41-1.41L10.83,12L15.41,7.41z"></path></svg>';

        nodeButtonLeft.addEventListener('click', slide);

        nodeNav.appendChild(nodeButtonLeft);

        nodeButtonRight = document.createElement('button');
        nodeButtonRight.classList.add('nav__button');
        nodeButtonRight.classList.add('nav__button--next');
        nodeButtonRight.dataset.dir = 'next';
        nodeButtonRight.innerHTML =
          '<svg class="button__svg" ' +
          'enable-background="new 8.59 6 7.41 12" viewBox="8.59 6 7.41 12" ' +
          'xmlns="http://www.w3.org/2000/svg"><path d="M10,6L8.59,7.41L13.17,' +
          '12l-4.58,4.59L10,18l6-6L10,6z"></path></svg>';

        nodeButtonRight.addEventListener('click', slide);

        nodeNav.appendChild(nodeButtonRight);
      }

      /**
       * Metodo para calcular el width de los elementos
       * a mostrar en pantalla.
       * @return {number} width de un item
       */
      function controlarNumElementosVisibles() {
        var widthItems = _widthMax;

        _numItemsVisibles = Math.floor(
          Math.abs(nodeContainer.offsetWidth / _widthMax)
        );

        if (_numItemsVisibles == items.length ) {
          widthItems = _widthMax;
        } else {
          widthItems = Math.floor(
            Math.abs(nodeContainer.offsetWidth / _numItemsVisibles));
        }

        return widthItems;
      }

      /**
       * Establece el width de cada elemento
       */
      function setWidthItems() {
        _numItemsVisibles = _showItems;

        if (_numItemsVisibles == 0) {
          _numItemsVisibles = items.length;
        }

        _widthItems = controlarNumElementosVisibles();

        items.forEach(function (element) {
          element.flex(_widthItems);
        });
      }

      /**
       * Retorna la posicion que ocupa el primer
       * elemento visible en las coordenadas calculadas
       * con anterioridad
       * @return {number} Posicion en las coordenadas
       */
      function getPositionCoordinate() {
        var left = parseInt(nodeListado.style.left, 10);
        var positionCoordinate = 0;

        if (left < 0) {
          left = left * -1;
          positionCoordinate = left / _widthItems;
        }
        return positionCoordinate;
      }

      /**
       * Establece las coordenadas en las que se encuentran
       * los items del carrusel
       */
      function setCoordinates() {
        var size = items.length;
        var iterator = -1;
        var previous = 0;
        var current = 0;
        var coordinates = [];

        while (++iterator < size) {
          previous = coordinates[iterator - 1] || 0;
          current = items[iterator].offsetWidth();
          coordinates.push(previous + current);
        }

        _coordinates = coordinates;
      }

      /**
       * Establece la variable _widthMax según tamaño
       * de pantalla
       */
      function inicializarWidthMax() {
        if (window.innerWidth > 768) {
          _widthMax = 150;
        } else {
          _widthMax = 85;
        }
      }
      /**
       * Establece el width de los items
       */
      function gestionarEstructuraCarrusel() {
        inicializarWidthMax();
        setWidthItems();
        setCoordinates();
        nodeListado.removeAttribute('style');
        var curWidth = Math.ceil(
          Math.abs(_coordinates[_coordinates.length - 1]));
        nodeListado.style.width = curWidth + 'px';
        nodeListado.style.left = '0px';
        setNavVisible();
      }

      /**
       * Muestra u oculta los botones de desplazamiento
       * dependiendo de si caben todos los items en
       * la ventana o no.
       */
      function setNavVisible() {
        if (_ocultarNav) {
          if (isShowNav()) {
            nodeNav.style.display = 'block';
          } else {
            nodeNav.style.display = 'none';
          }
        }
      }

      /**
       * @return {boolean} True si hay que mostrar los navs
       * y false si se pueden ocultar porque no hace falta.
       */
      function isShowNav() {
        return (
          nodeContainer.offsetWidth <
          Math.floor(Math.abs(_widthItems * items.length))
        );
      }

      /**
       * Evento para posicionarse en el indice indicado por parametro
       * @param {number} position que ocupa el item en las coordenadas
       */
      function moveItem(position) {
        if (isShowNav()) {
          nodeListado.style.removeProperty('left');
          if (position >= 0) {
            if (_alignCenter) {
              var sumarAPosition = Math.floor(Math.abs(_numItemsVisibles / 2));
              var coordenadaEncontrada = _coordinates[position];
              var posicionCentrada =
                coordenadaEncontrada - _widthItems * sumarAPosition;

              nodeListado.style.left = -1 * posicionCentrada + 'px';
            } else {
              nodeListado.style.left = -1 * (
                _coordinates[position - 1] || 0) + 'px';
            }
          } else if (_alignCenter) {
            nodeListado.style.left = _widthItems + 'px';
          }
        }
      }

      /**
       * Evento que devuelve el valor del elemento seleccionado
       * @return {String}
       */
      function getValueItemSelected() {
        var element = items.find(function (element) {
          return element.isSelected();
        });
        return element.elementButton.value;
      }

      /**
       * Evento para posicionarse en el elemento seleccionado
       */
      function moveItemSelected() {
        var index = items.findIndex(function (element) {
          return element.isSelected();
        });
        moveItem(index - 1);
      }

      /**
       * Evento para posicionarse en el elemento que contenga
       * el dia especificado por parametro
       * @param {number} selectItem dia a seleccionar
       */
      function moveItemToSelect(selectItem) {
        var index = items.findIndex(function (element) {
          if (element.isDay(selectItem)) {
            element.select();
            return true;
          }
          return false;
        });
        moveItem(index - 1);
      }

      /**
       * Evento para posicionarse en el primer elemento
       */
      function moveToFirstDay() {
        if (items.length > 0) {
          items[0].select();
          moveItem(-1);
        }
      }

      /**
       * Evento para los botones de desplazamiento
       */
      function slide() {
        var button = this;
        var dir = button.dataset.dir;
        var curPos = parseInt(nodeListado.style.left) || 0;
        var positionItem = Math.abs(curPos / _widthItems);
        var numItemsPosition = items.length - positionItem;

        if (dir == 'next') {
          if (numItemsPosition > _numItemsVisibles) {
            nodeListado.style.left = curPos - _widthItems + 'px'; // show frame
            // settings.slideMove
          } else {
            // comprobar si hay mas datos a dibujar
            console.log('No hay mas items.');
          }
        } else {
          if (numItemsPosition < items.length) {
            nodeListado.style.left = curPos + _widthItems + 'px'; // show frame
          } else {
            // comprobar si hay mas datos a dibujar
            console.log('No hay mas items.');
          }
        }
      }

      /**
       * Añade items en el inicio
       * @param {Array} arrayItems
       */
      function addItemsBefore(arrayItems) {
        addItems(arrayItems, true);
      }

      /**
       * Añade items en el final
       * @param {Array} arrayItems
       */
      function addItemsAfter(arrayItems) {
        addItems(arrayItems, false);
      }

      /**
       * Añade items al carrusel
       * @param {*} arrayItems
       * @param {*} addBefore
       */
      function addItems(arrayItems, addBefore) {
        var itemsSize = arrayItems.length;
        if (itemsSize == 0) {
          console.warn('No se puede añadir al carousel un array vacio.');
        }
        for (var i = 0; i < itemsSize; i++) {
          var item = arrayItems[i];

          var itemCarousel = itemFactory(
            item,
            _options.functions,
            '',
            nodeListado,
            addBefore
          );

          if (itemCarousel) {
            itemCarousel.registerObserver(returnedObject);
            if (addBefore) {
              items.unshift(itemCarousel);
            } else {
              items.push(itemCarousel);
            }
          }
        }
        gestionarEstructuraCarrusel();

        if (addBefore) {
          moveItem(itemsSize - 1);
        } else {
          moveItem(items.length - itemsSize);
        }
      }

      /**
       * Establece nuevos items
       * @param {Array} arrayItems
       */
      function setItems(arrayItems) {
        var itemsSize = arrayItems.length;
        if (itemsSize == 0) {
          console.warn('No se puede añadir al carousel un array vacio.');
        }
        items = [];
        // Vacio el listado de items para volver a cargar
        nodeListado.innerHTML = '';
        for (var i = 0; i < itemsSize; i++) {
          var item = arrayItems[i];

          var itemCarousel = itemFactory(
            item,
            _options.functions,
            '',
            nodeListado
          );

          if (itemCarousel) {
            itemCarousel.registerObserver(returnedObject);
            items.push(itemCarousel);
          }
        }
        gestionarEstructuraCarrusel();
      }

      /**
       * Instancia las pestañas y añade el click a cada una de ellas
       * @return {undefined}
       */
      (function init() {
        var carouselSize = options.items.length;
        if (carouselSize == 0) {
          console.warn('No se puede inicializar un carousel sin items.');
          return;
        }

        crearEstructura();
        // Crear los items
        items = [];
        for (var i = 0; i < carouselSize; i++) {
          var item = options.items[i];

          var itemCarousel = itemFactory(
            item,
            options.functions,
            options.selected,
            nodeListado
          );

          if (itemCarousel) {
            itemCarousel.registerObserver(returnedObject);
            items.push(itemCarousel);
          }
        }

        // Si no se ha especificado ninguna se selecciona la primera fecha
        if (
          node.querySelectorAll('.is-selected').length === 0 &&
          items.length > 0
        ) {
          items[0].select();
        }

        // timeOutFunctionId stores a numeric ID which is
        // used by clearTimeOut to reset timer
        var timeOutFunctionId;

        /**
         * Funcion que se ejecuta al terminar el resize
         * para calcular tamaños y posicion del carrusel
         */
        function workAfterResizeIsDone() {
          // Calcular posicion
          var position = getPositionCoordinate();
          gestionarEstructuraCarrusel();
          moveItem(position);
        }

        // The following event is triggered continuously
        // while we are resizing the window
        window.addEventListener('resize', function () {
          // clearTimeOut() resets the setTimeOut() timer
          // due to this the function in setTimeout() is
          // fired after we are done resizing
          clearTimeout(timeOutFunctionId);
          // setTimeout returns the numeric ID which is used by
          // clearTimeOut to reset the timer
          timeOutFunctionId = setTimeout(workAfterResizeIsDone, 500);
        });

        gestionarEstructuraCarrusel();
        node.addEventListener('swiped-right', function () {
          nodeButtonLeft.click();
        });

        node.addEventListener('swiped-left', function () {
          nodeButtonRight.click();
        });
        returnedObject.moveSelected();
      })();

      return returnedObject;
    }

    return {
      init: function (options) {
        var defaultOptions = {
          id: 'carousel__fechas',
          showItems: 0,
          move: 1,
          items: [],
          functions: [],
          selected: '',
          center: false,
          ocultarNav: false,
        };

        options = options = Object.assign({}, defaultOptions, options);

        var carousel = document.getElementById(options.id);
        if (!carousel) {
          console.warn(
            'No se puede inicializar el carousel de fechas. ' +
              'Tiene que existir el elemento con el id pasado por parametros'
          );
          return;
        }
        return carouselFactory(carousel, options);
      },
    };
  }
);
